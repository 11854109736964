/* eslint-disable i18next/no-literal-string */
import useTranslation from 'next-translate/useTranslation';

import styles from './NavbarLogoWrapper.module.scss';

import Link from '@/dls/Link/Link';

const NavbarLogoWrapper = () => {
  const { t } = useTranslation('common');
  return (
    <Link href="/" className={styles.logoWrapper} title={t('quran-com')}>
      {/* <KhatmaOnlineLogo /> */}
      <div className={styles.title}>{t('khatma-online')}</div>
    </Link>
  );
};

export default NavbarLogoWrapper;
