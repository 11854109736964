import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { fetchAuthSession } from '@aws-amplify/auth';

const httpLink = createHttpLink({
  uri: process.env.NEXT_PUBLIC_API_URL,
});

const authLink = setContext(async (a, { headers }) => {
  const session = await fetchAuthSession();

  return {
    headers: {
      ...headers,
      Authorization: session.tokens.accessToken.toString(),
    },
  };
});

const graphQLClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});

export default graphQLClient;
