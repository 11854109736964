/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/naming-convention */

import { isFirebaseEnabled } from 'src/lib/firebase';

export const logEvent = async (eventName: string, params?: { [key: string]: any }) => {
  if (isFirebaseEnabled) {
    import('src/lib/firebase').then((firebaseModule) => {
      // eslint-disable-next-line i18next/no-literal-string
      firebaseModule.analytics().logEvent(eventName, params);
    });
  }
};

/**
 * Log when a button is clicked.
 *
 * @param {string} buttonName
 */
export const logButtonClick = (buttonName: string, params?: { [key: string]: any }) => {
  logEvent(`${buttonName}_clicked`, params);
};

/**
 * Log when a form is submitted.
 *
 * @param {string} formName
 */
export const logFormSubmission = (formName: string, params?: { [key: string]: any }) => {
  logEvent(`${formName}_form_submitted`, params);
};

/**
 * Log when a carousel slide is completed.
 *
 * @param {string} carouselName
 * @param {number|string} slideNumber
 */
export const logCarouselSlideCompletion = (carouselName: string, slideNumber: number | string) => {
  logEvent(`${carouselName}_slide_${slideNumber}_completed`);
};

/**
 * Log when a value changes.
 *
 * @param {string} name
 * @param {string | boolean | number | string[] | number[] | Record<string, string>} currentValue
 * @param {string | boolean | number | string[] | number[] | Record<string, string>} newValue
 */
export const logValueChange = (
  name: string,
  currentValue: string | number | boolean | string[] | number[] | Record<string, any>,
  newValue: string | number | boolean | string[] | number[] | Record<string, any>,
  params: Record<string, unknown> = {},
) => {
  logEvent(`${name}_change`, {
    current_value: currentValue,
    new_value: newValue,
    ...params,
  });
};

export const logTarteelLinkClick = (type: string) => {
  logEvent('tarteel_link_click', {
    type: `${type}_attribution`,
  });
};

/**
 * Log when an item selection status change.
 *
 * @param {string} itemName
 * @param {string | number} itemId
 * @param {boolean} isSelected
 */
export const logItemSelectionChange = (
  itemName: string,
  itemId: string | number,
  isSelected = true,
) => {
  logEvent(`${itemName}_${isSelected ? 'selected' : 'unselected'}`, {
    value: itemId,
  });
};
