/* eslint-disable max-lines */
import { camelizeKeys } from 'humps';

import {
  makeChapterUrl,
  makeHizbVersesUrl,
  makeJuzVersesUrl,
  makePageVersesUrl,
  makePagesLookupUrl,
  makeVersesUrl,
} from '@/utils/apiPaths';
import { PagesLookUpRequest } from 'types/ApiRequests';
import { ChapterResponse, PagesLookUpResponse, VersesResponse } from 'types/ApiResponses';

export const OFFLINE_ERROR = 'OFFLINE';

export const fetcher = async function fetcher<T>(
  input: RequestInfo,
  init?: RequestInit,
): Promise<T> {
  // if the user is not online when making the API call
  if (typeof window !== 'undefined' && !window.navigator.onLine) {
    throw new Error(OFFLINE_ERROR);
  }
  const res = await fetch(input, init);
  if (!res.ok || res.status === 500 || res.status === 404) {
    throw res;
  }
  const json = await res.json();
  return camelizeKeys(json);
};

export const getChapterVerses = async (
  id: string | number,
  locale: string,
  params?: Record<string, unknown>,
): Promise<VersesResponse> => fetcher<VersesResponse>(makeVersesUrl(id, locale, params));

/**
 * Get a chapter's.
 *
 * @param {string} chapterIdOrSlug
 * @param {string} language
 * @returns {Promise<ChapterInfoResponse>}
 */
export const getChapter = async (
  chapterIdOrSlug: string,
  language: string,
): Promise<ChapterResponse> => fetcher(makeChapterUrl(chapterIdOrSlug, language));

/**
 * Get the verses of a specific Juz.
 *
 * @param {string} id the ID of the Juz.
 * @param {string} locale  the locale.
 * @param {string} params the params that we might need to include that differs from the default ones.
 *
 * @returns {Promise<VersesResponse>}
 */
export const getJuzVerses = async (
  id: string,
  locale: string,
  params?: Record<string, unknown>,
): Promise<VersesResponse> => fetcher(makeJuzVersesUrl(id, locale, params));

/**
 * Get the verses of a specific Hizb.
 *
 * @param {string} id the ID of the Hizb.
 * @param {string} locale  the locale.
 * @param {string} params the params that we might need to include that differs from the default ones.
 *
 * @returns {Promise<VersesResponse>}
 */
export const getHizbVerses = async (
  id: string,
  locale: string,
  params?: Record<string, unknown>,
): Promise<VersesResponse> => fetcher(makeHizbVersesUrl(id, locale, params));

/**
 * Get the verses of a specific page.
 *
 * @param {string} id the ID of the page.
 * @param {string} locale  the locale.
 * @param {string} params the params that we might need to include that differs from the default ones.
 *
 * @returns {Promise<VersesResponse>}
 */
export const getPageVerses = async (
  id: string,
  locale: string,
  params?: Record<string, unknown>,
): Promise<VersesResponse> => fetcher(makePageVersesUrl(id, locale, params));

/**
 * Get the footnote details.
 *
 * @param {PagesLookUpRequest} params
 *
 * @returns {Promise<PagesLookUpResponse>}
 */
export const getPagesLookup = async (params: PagesLookUpRequest): Promise<PagesLookUpResponse> =>
  fetcher(makePagesLookupUrl(params));

/**
 * Get the chapter id by a slug.
 *
 * @param {string} slug
 * @param {string} locale
 * @returns {Promise<false|string>}
 */
export const getChapterIdBySlug = async (slug: string, locale: string): Promise<false | string> => {
  try {
    const chapterResponse = await getChapter(encodeURI(slug), locale);
    return chapterResponse.chapter.id as string;
  } catch (error) {
    return false;
  }
};
